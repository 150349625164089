import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@env';
import { simpleKaysh } from '@karacas/kaysh';
import { TranslateService } from '@ngx-translate/core';
import { _getSelectedCheckBoxDataAsFilter } from '@prisma/components/check-box/check-box-v2/model/checkBoxModel';
import { GenericConfirmationModalComponent } from '@prisma/components/generic-confirmation-modal/generic-confirmation-modal.component';
import { _getEnv, _isDev, _isLab, _isStaging, _log, _logTap, _setDevPageTitle, _useDummyData, _warn } from '@shared/aux_helper_environment';
import { _cloneDeep, _extendObjDepp, _get, _handleErrorWithDummyData, _isBase64, _timeout } from '@shared/aux_helper_functions';
import * as versions from 'assets/versions.json';
import { AES, enc, mode, pad } from 'crypto-js';
import { of, Subject } from 'rxjs';
import { catchError, delay, first, map, tap } from 'rxjs/operators';
import { __prepareBrandCustomizationV2 } from './ian-prepareBrandCustomizationV2';
const JSON5 = require('json5');
import { Router } from '@angular/router';
import { DevSettingsService, FeatureFlagsKeysEnum } from './dev-settings.service';
import { GenericModalReporte, GenericModalWarningDelete } from '@prisma/components/generic-modal/generic-modal.component';

function __mergeDeep(target, source) {
  const isObject = obj => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = __mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue != null ? sourceValue : targetValue;
    }
  });

  return target;
}

function __replaceValue(obj, callBack) {
  if (obj == null) return obj;

  let newObj = {};

  for (let ogKey in obj) {
    if (typeof obj[ogKey] === 'string') {
      newObj[ogKey] = callBack(obj[ogKey]);
    } else if (typeof obj[ogKey] === 'object') {
      newObj[ogKey] = __replaceValue(obj[ogKey], callBack);
    }
  }

  return newObj;
}

const _aesCbcDec = (data, pass = 'verysecret1234551298765134567890') => {
  let key = enc.Utf8.parse(pass);
  let iv = enc.Utf8.parse('');

  let decrypted = AES.decrypt(data, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: mode.CBC,
    padding: pad.Pkcs7,
  });

  if (false) _log(decrypted.toString(enc.Utf8));
  return decrypted.toString(enc.Utf8);
};

let __getDefaultLang$ = ''; //DES-1640
export const _getDefaultLang$ = () => {
  return __getDefaultLang$;
};

export const _get$ = _getDefaultLang$;

@Injectable({
  providedIn: 'root',
})
export class IanTranslateService {
  // Los Pipes están en: id:FwkB6slaKt

  private _configLang = null;
  private _isDev: Boolean = _isDev();

  constructor(public _translateService: TranslateService) {
    if (!true) _log('[translateService]', this._translateService);

    if (window) {
      (window as any).__translateService = this._translateService;
    }

    this._translateService.onDefaultLangChange.subscribe(() => {
      if (this._configLang) this.prepareLangs(this._configLang);
    });
  }

  //DES-1640
  private _enabledLangUnits() {
    return _getEnv('enabledLangUnits');
  }

  private getDefaultLangUnit(key: '$' | 'cm.' | 'cm3' | 'm3') {
    return this._configLang[key];
  }

  public getDefaultLang$() {
    if (!this._enabledLangUnits()) return '$';

    return this.getDefaultLangUnit('$');
  }

  private _replace_lang_$(val) {
    let default$ = this.getDefaultLangUnit('$');

    if (!(val?.length > 0) || default$ == null || !String('').replaceAll) return val;

    let _$ = '_PRICE_SYMBOL_';

    return String(val).replaceAll('R$', _$).replaceAll('$', _$).replaceAll(_$, default$);
  }

  private _replace_lang_metersUnits(val) {
    if (!(val?.length > 0) || !String('').replaceAll) return val;

    let defaultCM = this.getDefaultLangUnit('cm.') || 'cm.';
    let defaultCM3 = this.getDefaultLangUnit('cm3') || 'cm³';
    let defaultM3 = this.getDefaultLangUnit('m3') || 'm³';

    return (
      val
        .replaceAll('cm.', defaultCM)
        //
        .replaceAll('cm³', defaultCM3)
        .replaceAll('cm3', defaultCM3)
        //
        .replaceAll('m³', defaultM3)
        .replaceAll('m3', defaultM3)
    );
  }

  private replaceValuesInLang(objLang) {
    if (!this._enabledLangUnits()) return objLang;

    let rv = __replaceValue(_cloneDeep(objLang), $val => {
      if (!($val?.length > 0)) return $val;

      let val = $val;
      val = this._replace_lang_$(val);
      val = this._replace_lang_metersUnits(val);

      return val;
    });

    return rv;
  }

  private setLangConfig(configLang) {
    if (configLang == null) return;
    this._configLang = configLang;
    __getDefaultLang$ = this.getDefaultLang$();
  }

  private _langsIsPrepare = false;
  prepareLangs(configLang) {
    //DES-1640

    this.setLangConfig(configLang);

    if (this._langsIsPrepare === true) return;

    const langs = this._translateService?.store?.translations;

    if (!langs?.es || !langs?.en || !this._configLang) return;

    const es = this.replaceValuesInLang(langs?.es);
    const en = __mergeDeep(_cloneDeep(es), this.replaceValuesInLang(langs?.en));
    const pt = __mergeDeep(_cloneDeep(en), this.replaceValuesInLang(langs?.pt));

    this._translateService.setTranslation('es', es, false);
    this._translateService.setTranslation('en', en, false);
    this._translateService.setTranslation('pt', pt, false);

    this._langsIsPrepare = true;
  }

  private instantForceByTest;
  private _noTranslateDevWarn = {};
  instant(key: string, vals: string | number | (string | number)[] = null, fallback: string = null): string {
    let rv = this.instantForceByTest ? this.instantForceByTest(key) : this._translateService.instant(key);

    if (vals != null) {
      if (!Array.isArray(vals)) {
        vals = [vals];
      }

      if (Array.isArray(vals)) {
        vals.forEach((newVal, i) => {
          rv = rv.replace('{{val' + (i > 0 ? i + 1 : '') + '}}', newVal);
        });
      }
    }

    if (!rv || rv == '' || rv === key) {
      if (this._isDev && this._noTranslateDevWarn[key] != true) {
        _warn('No translate key:', { rv, key, fallback });
        this._noTranslateDevWarn[key] = true;
      }

      rv = fallback;
    }

    return rv;
  }

  getDefaultLang(): string {
    return this._translateService.getDefaultLang() || 'es';
  }
}

const _devTest = true;

let _staticBrandCustomization: any = null;
export let _getStaticBrandCustomization = () => {
  return _staticBrandCustomization && _staticBrandCustomization.brandCustomization
    ? _cloneDeep(_staticBrandCustomization.brandCustomization)
    : null;
};

const DUMMY_DATA = {
  brandCustomization: {},
  versions,
  brandLangExtended: {},
};

@Injectable({
  providedIn: 'root',
})
export class PrismaDynamicEnv {
  private data = {};

  isLoaded = new Subject<boolean>();

  getConf(path: string, _default = null) {
    if (!path) {
      return null;
    }

    return _get(this.data, path, _default);
  }

  getData() {
    return _cloneDeep(this.data);
  }

  setStaticData() {
    _staticBrandCustomization = this.data;

    //Se vuelven a reiniciar las featureFlahs, pero esta vez extendiendo _staticBrandCustomization?.brandCustomization
    this.devSettingsService.initFeatureFlags(_staticBrandCustomization?.brandCustomization);
  }

  constructor(
    private http: HttpClient,
    private translateService: IanTranslateService,
    private router: Router,
    private devSettingsService: DevSettingsService
  ) {
    this.getAllConfigItemsFromServer();
  }

  private privateauxReloadCurrentRoute() {
    if (!this.router) return;

    let currentUrl = this.router.url;
    if (!currentUrl) return;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(async () => {
      await _timeout(1);
      this.router.navigate([currentUrl]);
    });
  }

  private getAllConfigItemsFromServer() {
    Object.entries(environment.prismaDynamicEnv).forEach(([itemKey, value]) => {
      this.getConfigItemFromServer(itemKey, value).subscribe(res => {
        if (true && res === undefined) return;

        this.data[itemKey] = res;

        if (Object.keys(this.data).length === Object.keys(environment.prismaDynamicEnv).length) {
          this.getAllConfigItemsFromServerEnd();
        }
      });
    });
  }

  private getAllConfigItemsFromServerEnd() {
    let brandCustomization = (this.data as any).brandCustomization;

    //Se inician los featureFlags de manera temporaria sin el nivel de BrandCustomization
    // (juatamente para poder iniciar BrandCustomization)
    this.devSettingsService.initFeatureFlags();

    if (environment.useBrandCustomizationV2) {
      this.data = __prepareBrandCustomizationV2(this.data, val => this.devSettingsService.hasFeatureFlag(val));
    }

    this.__prepareLangs(_cloneDeep(brandCustomization));

    if (true && _devTest) _log('%c[getAllConfigItemsFromServer] END / Data:', 'color: #9980ff', this.data);

    this.setStaticData();

    if (document.dispatchEvent) document.dispatchEvent(new Event('CUSTOMIZATION_IS_LOADED'));

    this.isLoaded.next(true);

    _setDevPageTitle();
  }

  private getConfigItemFromServer(itemKey, url) {
    const noCache = ['versions'];

    let avoidCache =
      environment.useLocalStorageToCacheBrandCustomization !== true ||
      !(environment.maxTimeToLocalStorageToCacheBrandCustomization > 0) ||
      noCache.includes(itemKey);

    if (_isDev() && true) avoidCache = true;

    if (avoidCache) return this._getConfigItemFromServer(itemKey, url);

    if (!url) return of(null).pipe(delay(1));

    const cache = simpleKaysh.getCacheValue('getConfigItemFromServer', url);
    if (cache != null) return of(cache).pipe(delay(1));

    let rv = this._getConfigItemFromServer(itemKey, url);

    rv.subscribe(value => {
      if (value == null) return;
      simpleKaysh.setCacheValue('getConfigItemFromServer', value, url, {
        localStorage: environment.useLocalStorageToCacheBrandCustomization === true,
        maxTime: environment.maxTimeToLocalStorageToCacheBrandCustomization,
      });
    });

    return rv;
  }

  private __prepareLangs(data) {
    let defaultlangUnits = environment.langUnits;

    let langUnits = { ...defaultlangUnits, ...((data as any)?.langUnits || {}) };

    this.translateService.prepareLangs(langUnits);
  }

  private _getConfigItemFromServer(itemKey, url) {
    if (!(url?.length > 0)) {
      return of(null).pipe(delay(1));
    }

    if (itemKey === 'brandCustomization' && environment.useBrandCustomizationV2) {
      return of(null).pipe(delay(1));
    }

    if (itemKey === 'brandCustomizationV2' && !environment.useBrandCustomizationV2) {
      return of(null).pipe(delay(1));
    }

    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    return this.http.get(url, { headers, responseType: 'text' }).pipe(
      first(),
      tap(data => _logTap(`${PrismaDynamicEnv.name}::getConfigItemFromServer (tap)\n\tdata: ${itemKey} %o`, (data || '').length)),
      map($data => {
        let data = $data && $data.length && $data[0] !== '{' && _isBase64($data) ? _aesCbcDec($data) : $data;

        if (data == null || data === '') {
          console.warn('[getConfigItemFromServer] empty val:', { itemKey, url, $data });
          return null;
        }

        try {
          if (data && typeof data === 'string') data = JSON5.parse(data);
        } catch (e) {
          console.warn('[getConfigItemFromServer]', { itemKey, url, $data }, e);
          return null;
        }

        return data;
      }),
      catchError(error =>
        _handleErrorWithDummyData(
          _useDummyData(),
          error,
          PrismaDynamicEnv,
          DUMMY_DATA[itemKey] && DUMMY_DATA[itemKey].default ? DUMMY_DATA[itemKey].default : null,
          itemKey
        )
      )
    );
  }
}

export interface GenericWarningCostsModel {
  urlBlob: string;
  translateCode: string;
  onlyAlert?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalAlertService {
  constructor(private dialog: MatDialog, private translate: IanTranslateService) {}

  promptAlertWarningCosts(warningCosts: GenericWarningCostsModel, $title?: string, $confirmText?: string): Promise<boolean> {
    if (true) _log('[promptAlertWarningCosts]', warningCosts);

    if (warningCosts == null) {
      throw new Error('[promptAlertWarningCosts] warningCosts is null');
    }

    const urlBlob = warningCosts.urlBlob;
    const translateCode = warningCosts.translateCode || 'TEST';
    const bodyMsg = this.translate.instant('GLOBAL_WARNINGS.' + translateCode, null, '');
    const fileMsg = this.translate.instant('GLOBAL_WARNINGS.MODAL_FILE_REPORT_TITLE', null, '');
    const onlyAlert = warningCosts.onlyAlert === true;

    let htmlContent = `
                <div class="publish_alert1">
                    ${bodyMsg}
                </div>
            `;

    htmlContent += urlBlob
      ? `

            <div class="publish_alert2">
                <b><a href="${urlBlob}" target="_blank">
                    ${fileMsg}
                </a></b>
            </div>
        `
      : ``;

    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '550px',
      data: {
        message: htmlContent,
        title:
          $title ||
          (!onlyAlert
            ? this.translate.instant('GLOBAL_WARNINGS.MODAL_WARNING_TITLE', null, '')
            : this.translate.instant('GLOBAL_WARNINGS.MODAL_WARNING_TITLE_ONLY_ALERT', null, '')),
        labelConfirm: $confirmText || (!onlyAlert ? this.translate.instant('ADMIN.MASTER.STORESV2.ITEM.PUBLISH', null, '') : null),
        labelCancel: !onlyAlert ? this.translate.instant('GENERIC_CANCEL', null, '') : this.translate.instant('GENERIC_OK', null, ''),
        disableClose: true,
        timeToConfirm: 720,
        needForceDetectChangesByClick: true,
      },
    });

    return dialogRef.afterClosed().toPromise();
  }

  simpleWarning(bodyMsg?: string, $title?: string, labelCancel = null, isOnlyWarning = true): Promise<boolean> {
    if (bodyMsg == null) return;

    if (true) _log('[simpleWarning]', bodyMsg);

    let htmlContent = `
                <div class="publish_alert1">
                    ${bodyMsg}
                </div>
            `;

    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '550px',
      data: {
        message: htmlContent,
        title: $title || this.translate.instant('GENERIC_NOTICE', null, ''),
        labelConfirm: this.translate.instant('GENERIC_OK', null, ''),
        labelCancel: labelCancel,
        needForceDetectChangesByClick: true,
        isOnlyWarning: isOnlyWarning,
      },
    });

    return dialogRef.afterClosed().toPromise();
  }

  customComponentBody(
    customComponentBody?: any,
    dataToInject?: any,
    $title?: string,
    $width?: string,
    $labelConfirm = null,
    $labelCancel = null,
    $labelReport = null
  ): Promise<any> {
    if (dataToInject == null) return;

    if (false) _log('[customComponentBody]', dataToInject, customComponentBody);

    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: $width || '650px',
      data: {
        component: customComponentBody,
        dataToInject: dataToInject,
        title: $title || this.translate.instant('GENERIC_NOTICE', null, ''),
        labelConfirm: $labelConfirm || this.translate.instant('GENERIC_OK', null, ''),
        labelCancel: $labelCancel,
        labelReport: $labelReport,
        needForceDetectChangesByClick: true,
      },
    });

    return dialogRef.afterClosed().toPromise();
  }

  async tryToDelete(typeOfService, row, filters, checkBoxData = null, $content = null, $title = null, $hasCancel = true) {
    const payload = this.getPayloadToService(row?.id, filters, checkBoxData, typeOfService);

    const content = $content
      ? $content
      : row?.name
      ? this.translate.instant('GENERIC_DELETE_LOGIC_SINGLE_CONTENT', row?.name)
      : checkBoxData
      ? this.translate.instant('GENERIC_DELETE_LOGIC_MULTIPLE_CONTENT', checkBoxData.getTotalItemsSelected())
      : null;
    const title = $title
      ? $title
      : row
      ? this.translate.instant('GENERIC_DELETE_LOGIC')
      : this.translate.instant('GENERIC_DELETE_MULTIPLE');

    const accepted = await this.generateModalWarning(content, title, $hasCancel);
    const response = {};
    response['accepted'] = accepted;
    response['payload'] = payload;
    return response;
  }

  async deleteWarning(row, checkBoxData = null, $content = null, $title = null, $hasCancel = true) {
    const content = $content
      ? $content
      : row?.name
      ? this.translate.instant('GENERIC_DELETE_LOGIC_SINGLE_CONTENT', row?.name)
      : checkBoxData
      ? this.translate.instant('GENERIC_DELETE_LOGIC_MULTIPLE_CONTENT', checkBoxData.getTotalItemsSelected())
      : null;

    const title = $title
      ? $title
      : row
      ? this.translate.instant('GENERIC_DELETE_LOGIC')
      : this.translate.instant('GENERIC_DELETE_MULTIPLE');

    return await this.generateModalWarning(content, title, $hasCancel);
  }

  payloadMultipleDelete(rowId, filters, checkBoxData) {
    const itemToDeleteByPayload = rowId || null;
    const _selectedItems = checkBoxData ? _getSelectedCheckBoxDataAsFilter(null, null, checkBoxData) : [];
    const filter = filters;

    let itemsToDelete: Array<any> = [];

    if (!itemToDeleteByPayload && _selectedItems && _selectedItems.length > 0) {
      _selectedItems.forEach(selectedItem => {
        itemsToDelete.push(selectedItem.id);
      });
    } else {
      itemsToDelete = itemToDeleteByPayload ? [itemToDeleteByPayload] : itemsToDelete;
    }

    const payload: any = {
      itemsIds: itemsToDelete,
      filter,
    };
    return payload;
  }

  getPayloadToService(rowId, filters, checkBoxData, typeOfService) {
    let payload = null;
    switch (typeOfService) {
      case 'delete':
        payload = this.payloadMultipleDelete(rowId, filters, checkBoxData);
        break;
      case 'undelete':
        payload = this.payloadMultipleDelete(rowId, filters, checkBoxData);
        break;
    }
    return payload;
  }

  async generateModalWarning(content, title, hasCancel = null) {
    const $content = content ? content : null;
    const $component = GenericModalReporte;
    const $dataToInject = { dataWarnings: $content };
    const $title = title ? title : null;
    const $cancel = hasCancel ? this.translate.instant('GENERIC_CANCEL', null) : null;
    return await this.customComponentBody($component, $dataToInject, $title, '550px', null, $cancel);
  }

  async generateModalReport(content, title, hasCancel = null, labelConfirm = null, labelReport = null, tradBase = 'IMV2') {
    const $content = content ? content : null;
    const $title = title ? title : null;
    const $labelConfirm = labelConfirm === null ? this.translate.instant('GENERIC_OK') : labelConfirm;
    const $labelReport = labelReport;

    const $component = GenericModalWarningDelete;
    const $dataToInject = { dataWarnings: $content, tradBase: tradBase };
    const $cancel = hasCancel ? this.translate.instant('GENERIC_CANCEL', null) : null;
    return await this.customComponentBody($component, $dataToInject, $title, '550px', $labelConfirm, $cancel, $labelReport);
  }
}
